// src/hooks/useContract.js

import { useEffect, useState } from 'react';
import { BrowserProvider, Contract, formatUnits } from 'ethers'
import NodesABI from '../abis/NodesABI.json'; // ABI of your contract
import CollectionABI from '../abis/CollectionABI.json'; // ABI of your contract
import SETTINGS from "../SETTINGS";

export const useContract = (provider) => {
    const [collections, setCollections] = useState([]);
    const [ownedNFTs, setOwnedNFTs] = useState([]);
    const [totalRewards, setTotalRewards] = useState(0);

    const fetchCollections = async () => {
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        
        let collectionsArr = [];
        const collection = await contract.collection();
       
        const collectionContract = new Contract(collection, CollectionABI, signer);
        const minted = await collectionContract.minted();
        const name = await collectionContract.name();
        const symbol = await collectionContract.symbol();
        const maxSupply = await collectionContract.maxSupply();
        const tokenURI = await collectionContract.tokenURI(minted);
        const price = await contract.getPrice(minted);
      
        
        collectionsArr.push({
            name:name,
            symbol:symbol,
            imageURL:tokenURI,
            priceUSDTWei: price,
            priceUSDT: formatUnits(price, 6),
            maxSupply:parseInt(maxSupply),
            minted:parseInt(minted),
            isAvailable: true,
            availableToMint: minted
        });
        
        setCollections(collectionsArr);
        
    };


    const fetchOwnedNFTs = async () => {
        /*
        try{
            const ethersProvider = new BrowserProvider(provider);
            const signer = await ethersProvider.getSigner();
            const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
            let tokendIds = [];
            let mintTimes = [];
            let lastClaims = [];
            for(let i=1;i<150;i++){
                try{
                    const mintTime = await contract.mintTime(i);
                    const lastClaim = await contract.lastClaim(i);

                    if(mintTime<=10){
                        console.log("con");
                        continue;
                    }
                    console.log(i);
                    tokendIds.push(i);
                    console.log("mintTime");
                    console.log(mintTime.toString());
                    mintTimes.push(mintTime.toString());
                    console.log("lastClaim");
                    console.log(lastClaim);
                    lastClaims.push(lastClaim.toString());
                } catch(e){
                    console.log(e);
                    continue;
                }
            }
            console.log("tokendIds");
            console.log(JSON.stringify(tokendIds));
            console.log("mintTimes");
            console.log(JSON.stringify(mintTimes));
            console.log("lastClaims");
            console.log(JSON.stringify(lastClaims));

        } catch(e){
            console.log(e);
        }
        */
        try{
        
        const ethersProvider = new BrowserProvider(provider);
        const signer = await ethersProvider.getSigner();
        const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
        
       
        const account = await signer.getAddress();
        const nftsForUser = await contract.getAllNFTsForUser(account);
       
        const serialized = JSON.stringify(nftsForUser, (key, value) =>
        typeof value === 'bigint' ? value.toString() : value
        );
        const nfts = JSON.parse(serialized);
   
        let nftsArr = [];
        let totalRewards = 0;
        for(let i=0;i<nfts.length;i++){
            let unclaimedRewards = "0";
            try{
                const calculateRewards = await contract.calculateRewards(parseInt(nfts[i][1]));
                
                unclaimedRewards = formatUnits(calculateRewards.toString(), 18);
            }catch(error){
                console.log("error calculate rewards");
            }
            
            totalRewards += parseFloat(unclaimedRewards);
            nftsArr.push(
                {
                    collectionAddress: nfts[i][0],
                    tokenId:nfts[i][1],
                    name:nfts[i][2],
                    imageURL:nfts[i][3],
                    unclaimedRewards:parseFloat(unclaimedRewards).toFixed(2),
                }
            );

        
        }

        setTotalRewards(totalRewards.toFixed(2));
        setOwnedNFTs(nftsArr);
    } catch(e){}
    };

    useEffect(() => {
        if (!provider) return;

        

      

        
        fetchCollections();
        fetchOwnedNFTs();
    }, [provider]);

    return { collections, ownedNFTs, fetchCollections, fetchOwnedNFTs, totalRewards };
};
