import React, { useState, useEffect } from 'react';
import { Container, Button, Table, Spinner, FormControl, InputGroup, Modal, Form, Pagination } from 'react-bootstrap';
import { BrowserProvider, Contract, formatUnits } from 'ethers';
import { FaInfoCircle, FaPlusCircle, FaLock, FaChartLine, FaBullhorn, FaUsers, FaSort, FaSearch  } from 'react-icons/fa';

import SETTINGS from '../SETTINGS';
import NodesABI from '../abis/NodesABI.json'; // Import Nodes ABI
import CollectionABI from '../abis/CollectionABI.json'; // Import Collection ABI
import {ERC20_ABI} from '../abis/erc20.js'; // Import    ERC20_ABI      ABI
import { wait } from '@testing-library/user-event/dist/utils/index.js';


const SAFE_ADDRESS = '0x511C645389eBe73aecFfaA57924d14ec46c13de8';
const STAKING_ADDRESS = '0x62766990101d74f393F25e538191c00C40cB1fe4';
const MARKETING_ADDRESS = '0x27Ac53EF2B3D37fFee3aa11bf9E5B81c876D3572';
const COMMUNITY_REWARDS_ADDRESS = '0x43e855635009b1f04fc8961852638B895A272020';

// Utility function to truncate Ethereum addresses
const truncateAddress = (address) => {
  if (!address) return '';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

// Component to display a clickable address with its balance
const AddressWithBalance = ({ address, provider }) => {
  const [tokenBalance, setTokenBalance] = useState(null);
  const [usdtBalance, setUsdtBalance] = useState(null);

  useEffect(() => {
    const fetchBalances = async () => {
      const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(SETTINGS.tokenAddress, ERC20_ABI, signer);
      const usdtContract = new Contract(SETTINGS.usdtAddress, ERC20_ABI, signer);

      const [tokenBal, usdtBal] = await Promise.all([
        tokenContract.balanceOf(address),
        usdtContract.balanceOf(address),
      ]);
      const options = {
        style: 'decimal',  // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      setTokenBalance(parseFloat(formatUnits(tokenBal, 18)).toLocaleString('en-US', options)); // Assuming 18 decimals for ERC20
      setUsdtBalance(parseFloat(formatUnits(usdtBal, 6)).toLocaleString('en-US', options)); // Assuming 6 decimals for USDT
    };

    fetchBalances();
  }, [address, provider]);

  return (
    <div>
      <a
        href={`https://snowtrace.io/address/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        title={address}
        className='buton smallBtn btn btn-primary'
      >
        {truncateAddress(address)}
      </a>
      <div>
        <small>Token: {tokenBalance || 'Loading...'}</small>
        <br />
        <small>USDT: {usdtBalance || 'Loading...'}</small>
      </div>
    </div>
  );
};

function AdminNew({ provider, address }) {
  const [tokenListData, setTokenListData] = useState([]);
  const [totalSupply, setTotalSupply] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'tokenId', direction: 'ascending' });
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);



  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handleDailyDistribute = async () => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const tx2 = await contract.transferErcTokens(SETTINGS.tokenAddress, "0x62766990101d74f393F25e538191c00C40cB1fe4", "897590360000000000000000");
      await tx2.wait();
      const tx3 = await contract.transferErcTokens(SETTINGS.tokenAddress, "0x27Ac53EF2B3D37fFee3aa11bf9E5B81c876D3572", "897590360000000000000000");
      await tx3.wait();
      const tx4 = await contract.transferErcTokens(SETTINGS.tokenAddress, "0x43e855635009b1f04fc8961852638B895A272020", "1795181000000000000000000");
      await tx4.wait();
      
      const tx1 = await contract.dailyDistribute();
      await tx1.wait();
      
   

      alert('Daily distribution executed successfully');
    } catch (error) {
      console.error('Error executing daily distribution:', error);
      alert('Failed to execute daily distribution. Please try again.');
    }
  };

  const fetchTokenData = async () => {
    setLoading(true);
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      console.log('Fetching data from contract...');
      const nodeContract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      const collectionContract = new Contract(SETTINGS.collection, CollectionABI, signer);

      // Call the exportData function
      const [tokenIds, mintTimes, lastClaims, referrers] = await nodeContract.exportData();

      const totalSupply = tokenIds.length;
      setTotalSupply(totalSupply);

      const tokenList = await Promise.all(tokenIds.map(async (tokenId, index) => {
        const owner = await collectionContract.ownerOf(tokenId);
        const claimedRewards = parseFloat(mintTimes[index]) - parseFloat(lastClaims[index]); // Placeholder for claimed rewards calculation
        const unclaimedRewards = await nodeContract.calculateRewards(tokenId);
        if(mintTimes[index].toString() == "0"){
          console.log(owner);
        }
        return {
          tokenId: parseInt(tokenId.toString()),
          mintTime: new Date(parseInt(mintTimes[index].toString()) * 1000).toLocaleString(),
          lastClaim: new Date(parseInt(lastClaims[index].toString()) * 1000).toLocaleString(),
          mintTimeTimestamp: mintTimes[index].toString(),
          lastClaimTimestamp: lastClaims[index].toString(),
          referrer: referrers[index],
          owner,
          claimedRewards,
          unclaimedRewards: unclaimedRewards.toString(),
        };
      }));

      setTokenListData(tokenList);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const sortedAndFilteredData = React.useMemo(() => {
    let sortedData = [...tokenListData];

    // Sorting logic
    if (sortConfig !== null) {
      sortedData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    // Filter logic
    if (searchTerm) {
      sortedData = sortedData.filter((token) =>
        token.tokenId.toString().includes(searchTerm) ||
        token.referrer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        token.owner.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return sortedData;
  }, [tokenListData, sortConfig, searchTerm]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleShowInfoModal = (token) => {
    setSelectedToken(token);
    setShowInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
    setSelectedToken(null);
  };

  const handleShowReferralModal = () => {
    setShowReferralModal(true);
  };

  const handleCloseReferralModal = () => {
    setShowReferralModal(false);
  };

  const handleAddressClick = (address) => {
    setSearchTerm(address);
  };

  const currentTableData = sortedAndFilteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAddReferral = async (user, percent, code) => {
    const ethersProvider = new BrowserProvider(provider);
    const signer = await ethersProvider.getSigner();

    try {
      const contract = new Contract(SETTINGS.nodeContract, NodesABI, signer);
      await contract.addRefferal(user, percent, code);
      alert('Referral added successfully');
    } catch (error) {
      console.error('Error adding referral:', error);
      alert('Failed to add referral. Please try again.');
    }
  };

  return (
    <Container className='pokemon-card mt-4'>
      <h2>Admin</h2>
            <Button onClick={handleShowReferralModal} variant="primary" className="mb-1 mt-2 buton smallBtn btn btn-primary">
        <FaPlusCircle /> Add Referral
      </Button>
      <Button onClick={handleDailyDistribute} variant="primary" className="mb-1 mt-2 buton smallBtn btn btn-primary">
        Distribute Daily
      </Button>
      <hr />

       {/* Address Balances Section */}

<div className="mt-2 ml-2">

  <div className="row">
    <div className="col-md-3">
      <FaLock className="mb-1" size={20} /> <strong>Safe Address</strong>
      <AddressWithBalance address={SAFE_ADDRESS} provider={provider} />
    </div>
    <div className="col-md-3">
      <FaChartLine className="mb-1" size={20} /> <strong>Staking Address</strong>
      <AddressWithBalance address={STAKING_ADDRESS} provider={provider} />
    </div>
    <div className="col-md-3">
      <FaBullhorn className="mb-1" size={20} /> <strong>Marketing Address</strong>
      <AddressWithBalance address={MARKETING_ADDRESS} provider={provider} />
    </div>
    <div className="col-md-3">
      <FaUsers className="mb-1" size={20} /> <strong>Community Rewards</strong>
      <AddressWithBalance address={COMMUNITY_REWARDS_ADDRESS} provider={provider} />
    </div>
  </div>
</div>


      <hr />

      {loading ? (
        <center>   
          <br />
          <br />
          <br />
          <Spinner animation="border" role="status">
          </Spinner>
          <br />
          <span className="sr-only">Loading ...</span>
          <br />
          <br />
          <br />
          <br />
        </center>
      ) : (
        <>
        <div className="d-flex justify-content-between mb-2">
          <div>
          <FormControl
          placeholder="Search by Token ID, Owner, or Referrer"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
            <span>
              Showing {Math.min(currentPage * itemsPerPage, sortedAndFilteredData.length)} of {sortedAndFilteredData.length} results
            </span>
          </div>
      
          <div>
            <select
              className="form-select"
              style={{ width: 'auto' }}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            >
         
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={300}>300</option>
            </select>
          </div>
        </div>
      
        <Table striped bordered hover>
          <thead>
            <tr>
              <th onClick={() => requestSort('tokenId')}>
                Token ID <FaSort />
              </th>
              <th onClick={() => requestSort('mintTimeTimestamp')}>
                Mint Time <FaSort />
              </th>
              <th onClick={() => requestSort('lastClaimTimestamp')}>
                Last Claim <FaSort />
              </th>
              <th onClick={() => requestSort('owner')}>
                Owner <FaSort />
              </th>
              <th onClick={() => requestSort('referrer')}>
                Referrer <FaSort />
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentTableData.map((token) => (
              <tr key={token.tokenId}>
                <td>{token.tokenId}</td>
                <td>
                  <small>
                    {token.mintTime}<br />
                    {token.mintTimeTimestamp}
                  </small>
                </td>
                <td>
                  <small>
                    {token.lastClaim}<br />
                    {token.lastClaimTimestamp}
                  </small>
                </td>
                <td>
                  <a href="#" onClick={() => handleAddressClick(token.owner)} className='buton smallBtn btn btn-primary' >
                  <FaSearch />  {truncateAddress(token.owner)}
                  </a>
                </td>
                <td>
                  <a href="#" onClick={() => handleAddressClick(token.referrer)} className='buton smallBtn btn btn-primary' >
                  <FaSearch /> {truncateAddress(token.referrer)}
                  </a>
                </td>
                <td>
                  <Button variant="primary" className='buton smallBtn btn btn-primary' onClick={() => handleShowInfoModal(token)}>
                    <FaInfoCircle /> 
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      
        <div className="d-flex justify-content-center">
          <Pagination>
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </div>
      </>
      )}

      {/* Token Info Modal */}
      <Modal show={showInfoModal} onHide={handleCloseInfoModal}>
        <Modal.Header closeButton>
          <Modal.Title>Token ID: {selectedToken?.tokenId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedToken && (
            <div>
              <p>
                <strong>Owner:</strong> {truncateAddress(selectedToken.owner)}
                <br />
                <small><a href={`https://snowtrace.io/address/${selectedToken.owner}`} target="_blank" rel="noopener noreferrer">View on SnowTrace</a></small>
              </p>
              <p><strong>Mint Time:</strong> {selectedToken.mintTime}</p>
              <p><strong>Last Claim:</strong> {selectedToken.lastClaim}</p>
              <p><strong>Claimed Rewards:</strong> {selectedToken.claimedRewards}</p>
              <p><strong>Unclaimed Rewards:</strong> {selectedToken.unclaimedRewards}</p>
              <p>
                <strong>Referrer:</strong> {truncateAddress(selectedToken.referrer)}
                <br />
                <small><a href={`https://snowtrace.io/address/${selectedToken.referrer}`} target="_blank" rel="noopener noreferrer">View on SnowTrace</a></small>
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseInfoModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Referral Modal */}
      <Modal show={showReferralModal} onHide={handleCloseReferralModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Referral</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => {
            e.preventDefault();
            const form = e.target;
            handleAddReferral(form.user.value, form.percent.value, form.code.value);
            form.reset();
            handleCloseReferralModal();
          }}>
            <Form.Group>
              <Form.Label>User Address</Form.Label>
              <Form.Control name="user" type="text" placeholder="Enter user address" required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Referral Percent</Form.Label>
              <Form.Control name="percent" type="number" min="0" max="100" placeholder="Enter referral percent" required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Invite Code</Form.Label>
              <Form.Control name="code" type="text" placeholder="Enter invite code" required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Add Referral
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default AdminNew;
